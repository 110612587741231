<template>
	<div class="min-h-screen pt-12">
		<div class="px-5 py-5">
			<div class="flex flex-col min-h-dashboard-screen">
				<div class="flex flex-1 flex-col">
					<div class="mb-8">
						<h2 class="text-xl font-bold">メッセージ詳細</h2>
					</div>
					<div v-if="isFetchingMessageData" class="grid grid-cols-2 mb-8">
						<div class="grid">
							<div class="flex flex-col">
								<div class="flex flex-row mb-4">
									<div class="w-28">
										<div class="w-20 h-6 bg-gray-300 animate-pulse"></div>
									</div>
									<div class="w-72 h-6 bg-gray-300 animate-pulse"></div>
								</div>
								<div class="flex flex-row mb-4">
									<div class="w-28">
										<div class="w-20 h-6 bg-gray-300 animate-pulse"></div>
									</div>
									<div class="w-32 h-6 bg-gray-300 animate-pulse"></div>
								</div>
								<div class="flex flex-row mb-4">
									<div class="w-28">
										<div class="w-20 h-6 bg-gray-300 animate-pulse"></div>
									</div>
									<div class="w-32 h-6 bg-gray-300 animate-pulse"></div>
								</div>
								<div class="flex flex-row mb-4">
									<div class="w-28">
										<div class="w-20 h-6 bg-gray-300 animate-pulse"></div>
									</div>
									<div class="w-32 h-6 bg-gray-300 animate-pulse"></div>
								</div>
								<div class="flex flex-row mb-4">
									<div class="w-28">
										<div class="w-20 h-6 bg-gray-300 animate-pulse"></div>
									</div>
									<div class="w-96">
										<div class="w-full h-6 mb-2 bg-gray-300 animate-pulse"></div>
										<div class="w-full h-6 mb-2 bg-gray-300 animate-pulse"></div>
										<div class="w-full h-6 mb-2 bg-gray-300 animate-pulse"></div>
										<div class="w-full h-6 mb-2 bg-gray-300 animate-pulse"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="flex flex-col">
								<div class="w-20 h-6 bg-gray-300 animate-pulse mb-4"></div>
								<div class="w-full h-80 bg-gray-300 animate-pulse mb-2"></div>
								<div class="w-full h-80 bg-gray-300 animate-pulse mb-2"></div>
							</div>
						</div>
					</div>
					<div v-else class="grid grid-cols-2 mb-8">
						<div class="grid">
							<div class="flex flex-col">
								<div class="flex flex-row mb-4">
									<h3 class="min-w-28 font-bold">タイトル</h3>
									<p style="overflow-wrap: break-word; word-break: break-word; white-space: pre-line">
										{{ messageDetail.message_title }}
									</p>
								</div>
								<div v-if="messageDetail.publish_date" class="flex flex-row mb-4">
									<h3 class="min-w-28 font-bold">公開日</h3>
									<p>{{ messageDetail.publish_date }}</p>
								</div>
								<div class="flex flex-row mb-4">
									<h3 class="min-w-28 font-bold">公開状態</h3>
									<p>{{ messageDetail.publicize_flag ? '表示' : '非表示' }}</p>
								</div>
								<div class="flex flex-row mb-4">
									<h3 class="min-w-28 font-bold">タイプ</h3>
									<p>
										{{ messageDetail.sender_type === 0 ? 'お知らせ' : '応援メッセージ' }}
									</p>
								</div>
								<div v-if="messageDetail.sender_type === 1" class="flex flex-row mb-4">
									<h3 class="min-w-28 font-bold">応援者</h3>
									<p style="overflow-wrap: break-word; word-break: break-word; white-space: pre-line">
										{{ messageDetail.sender_name }}
									</p>
								</div>
								<div class="flex flex-row">
									<h3 class="min-w-28 font-bold">内容</h3>
									<p
										style="overflow-wrap: break-word; word-break: break-word; white-space: pre-line"
										v-html="renderMessageContent(messageDetail.message || '')"
									></p>
								</div>
							</div>
						</div>
						<div v-if="messageMediumList.length !== 0" class="grid">
							<div class="flex flex-col mb-10">
								<h3 class="font-bold mb-4">メディア</h3>
								<div class="flex flex-col">
									<div v-for="(medium, index) in messageMediumList" :key="index" class="mb-2">
										<img
											v-if="medium.medium_type === 1"
											:src="medium.link"
											:alt="`Message media #${index}`"
											class="w-full"
										/>
										<audio v-if="medium.medium_type === 2" controls class="w-full">
											<source :src="medium.link" type="audio/mpeg" />
											Your browser does not support the audio element.
										</audio>
										<iframe
											v-if="medium.medium_type === 3"
											class="w-full h-80"
											:src="medium.link"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="flex flex-row items-center justify-start">
						<router-link
							:to="{ name: 'messages_list' }"
							class="w-32 py-2 focus:outline-none bg-gray-300 text-center mr-16"
						>
							戻る
						</router-link>
						<router-link
							:to="{
								name: 'messages_edit',
								params: { messageId: $route.params.messageId }
							}"
							class="w-32 py-2 focus:outline-none bg-warning-300 text-center mr-16"
						>
							編集
						</router-link>
						<button
							type="button"
							class="w-32 py-2 focus:outline-none bg-danger-500 text-center"
							@click="handleShowDeleteConfirmModal"
						>
							削除
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<confirm-modal
		v-if="showDeleteConfirmModal"
		title="このメッセージを削除しますか？"
		@on-cancel="handleHideDeleteConfirmModal"
		@on-submit="handleDeleteMessage"
	/>
</template>

<script>
import dayjs from 'dayjs';
import firebase from 'firebase';
import 'firebase/firestore';

import ConfirmModal from '@/components/ConfirmModal.vue';
import { REGEX_URL_IN_TEXT } from '@/constants/constants';

export default {
	components: {
		ConfirmModal
	},
	data() {
		return {
			isFetchingMessageData: false,
			messageDetail: {},
			messageMediumList: [],
			showDeleteConfirmModal: false
		};
	},
	beforeCreate() {
		document.title = 'メッセージ詳細 | ASP';
	},
	mounted() {
		this.handleFetchMessageData();
	},
	methods: {
		async handleFetchMessageData() {
			try {
				if (this.isFetchingMessageData || !this.$route.params.messageId) return;
				this.isFetchingMessageData = true;

				const messageDocumentSnapshot = await firebase
					.firestore()
					.collection('d_message')
					.doc(this.$route.params.messageId ?? '')
					.get();

				if (!messageDocumentSnapshot.exists) {
					this.isFetchingMessageData = false;
					await this.$router.push({ name: 'error_404' });
				}
				const messageData = messageDocumentSnapshot.data();

				if (messageData.delete_flag !== 0) {
					this.isFetchingMessageData = false;
					await this.$router.push({ name: 'error_404' });
				}

				const publishDate = messageData.publish_date
					? dayjs.tz(messageData.publish_date, 'Etc/GMT+0').format('YYYY年MM月DD日')
					: null;

				this.messageDetail = {
					...messageDocumentSnapshot.id,
					...messageData,
					publish_date: publishDate
				};

				const messageMediumListDocumentSnapshot = await firebase
					.firestore()
					.collection('d_message_medium')
					.where('message_id', '==', messageDocumentSnapshot.id)
					.get();

				this.messageMediumList = messageMediumListDocumentSnapshot.docs.map(medium => {
					return {
						...medium.id,
						...medium.data()
					};
				});

				this.messageMediumList.sort((pic1, pic2) => {
					return pic1.medium_order - pic2.medium_order;
				});

				this.isFetchingMessageData = false;
			} catch (error) {
				this.isFetchingMessageData = false;
				await this.$router.push({ name: 'error_404' });
			}
		},
		handleShowDeleteConfirmModal() {
			if (this.showDeleteConfirmModal) return;
			this.showDeleteConfirmModal = true;
		},
		handleHideDeleteConfirmModal() {
			if (!this.showDeleteConfirmModal) return;
			this.showDeleteConfirmModal = false;
		},
		handleDeleteMessage() {
			firebase
				.firestore()
				.collection('d_message')
				.doc(this.$route.params.messageId ?? '')
				.update({
					delete_flag: 1
				})
				.then(() => {
					this.$toast('メッセージを削除しました！', { type: 'success' });
				})
				.catch(() => {
					this.$toast('メッセージを削除失敗しました！', { type: 'danger' });
				})
				.then(() => {
					this.handleHideDeleteConfirmModal();
					this.$router.push({ name: 'messages_list' });
				});
		},
		renderMessageContent(content) {
			return content
				.split(REGEX_URL_IN_TEXT)
				.map((part, index) =>
					index % 2 === 0
						? part
						: `<a class='message-url' target='_blank' rel='nofollow noopener noreferrer' key={index} href='${part}'>${part}</a>`
				)
				.join('');
		}
	}
};
</script>

<style scoped>
.min-h-dashboard-screen {
	min-height: calc(100vh - 5.5rem);
}
.min-w-28 {
	min-width: 7rem;
}
</style>
<style lang="scss">
.message-url {
	color: #1a50b7;
	&:hover {
		text-decoration: underline;
	}
}
</style>
