<template>
	<div
		class="w-screen h-screen fixed top-0 left-0 z-50 flex flex-row justify-center
		items-center bg-dark-transparent-500"
	>
		<div class="bg-white px-16 py-10 animate-zoom-in-fast">
			<h4 class="text-center text-xl mb-8">{{ title }}</h4>
			<h4 class="text-center text-xl mb-8">{{ subTitle }}</h4>
			<div class="flex flex-row justify-center">
				<button class="px-4 py-2 bg-gray-200 focus:outline-none mr-8" @click="$emit('on-cancel')">
					{{ cancelText }}
				</button>
				<button
					class="px-4 py-2 text-white focus:outline-none"
					:class="[submitButtonClass ? submitButtonClass : '', isSubmitting ? 'cursor-wait' : '']"
					@click="$emit('on-submit')"
				>
					{{ submitText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmModal',
	props: {
		title: {
			type: String,
			default: 'よろしいですか？'
		},
		subTitle: {
			type: String,
			default: ''
		},
		cancelText: {
			type: String,
			default: 'いいえ'
		},
		submitText: {
			type: String,
			default: 'はい'
		},
		submitButtonClass: {
			type: String,
			default: 'bg-danger-500'
		},
		isSubmitting: {
			type: Boolean,
			default: false
		}
	},
	emits: ['on-cancel', 'on-submit']
};
</script>
